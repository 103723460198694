import { Icon, IconProps } from "@chakra-ui/react";
const MoneybagAPlusIcon = (props: IconProps) => <Icon width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} data-sentry-element="Icon" data-sentry-component="MoneybagAPlusIcon" data-sentry-source-file="A_PLUS.tsx">
    <g clipPath="url(#a)" data-sentry-element="g" data-sentry-source-file="A_PLUS.tsx">
      <path d="M15.857 8H8.143C5.268 10.07 3 13.95 3 17.002 3 22 7.782 22 12 22s9 0 9-4.998c0-3.051-2.268-6.933-5.143-9.002ZM8.667 7h6.666l1.509-2.837c.206-.44.21-.99.014-1.438-.198-.448-.567-.725-.967-.725h-1.667c-.294 0-.577.15-.785.419l-.881 1.132-.881-1.132C11.466 2.15 11.183 2 10.889 2H8.11c-.4 0-.77.277-.967.725-.197.448-.192.997.014 1.438L8.667 7Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="A_PLUS.tsx" />
      <path d="M6.121 18.08a.644.644 0 0 1-.39-.11.45.45 0 0 1-.17-.29.757.757 0 0 1 .07-.42l2.64-5.85c.087-.193.19-.33.31-.41a.743.743 0 0 1 .43-.13c.154 0 .29.043.41.13.127.08.234.217.32.41l2.65 5.85c.074.153.1.293.08.42a.457.457 0 0 1-.17.3.623.623 0 0 1-.37.1c-.186 0-.333-.043-.44-.13-.1-.093-.19-.233-.27-.42l-.65-1.51.54.35h-4.22l.54-.35-.64 1.51a1.304 1.304 0 0 1-.27.42c-.093.087-.226.13-.4.13Zm2.87-5.74-1.4 3.33-.26-.32h3.34l-.25.32-1.41-3.33h-.02Zm6.732 5.34c-.147 0-.26-.04-.34-.12a.496.496 0 0 1-.12-.35v-1.64h-1.61a.47.47 0 0 1-.33-.11.446.446 0 0 1-.12-.33c0-.14.04-.247.12-.32.08-.08.19-.12.33-.12h1.61V13.1c0-.147.04-.26.12-.34.08-.08.196-.12.35-.12.146 0 .256.04.33.12.08.08.12.193.12.34v1.59h1.61c.146 0 .256.04.33.12.08.073.12.18.12.32s-.04.25-.12.33c-.074.073-.184.11-.33.11h-1.61v1.64c0 .147-.04.263-.12.35-.074.08-.187.12-.34.12Z" fill="#fff" data-sentry-element="path" data-sentry-source-file="A_PLUS.tsx" />
    </g>
    <defs data-sentry-element="defs" data-sentry-source-file="A_PLUS.tsx">
      <clipPath id="a" data-sentry-element="clipPath" data-sentry-source-file="A_PLUS.tsx">
        <path fill="#fff" d="M0 0h24v24H0z" data-sentry-element="path" data-sentry-source-file="A_PLUS.tsx" />
      </clipPath>
    </defs>
  </Icon>;
export default MoneybagAPlusIcon;